import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import uiuxDesign from "../assets/img/uiuxdesign.png";
import uiuxSolitions from "../assets/img/uiux-solitions.png"
import uiuxSolitions2 from "../assets/img/uiux-solitions2.png"
import tcddDev from "../assets/img/tcdd-dev.png"
import lines from "../assets/img/liness.png"
import arcelikk from "../assets/img/arcelik-category.webp"
import toyota from "../assets/img/toyota-ui-tasarim.webp"
import popupsmartDev from "../assets/img/popupsmart-dev.png"
import circle from "../assets/img/circle.png"
import lavarel from "../assets/img/lavarel.png"
import vue from "../assets/img/vue-core.png"
import Gallery from "../components/gallery/gallery"
import balance from "../assets/img/balance-time-money.png"
import achievement from "../assets/img/achievement-female.png"
import videoSearch from "../assets/img/video-search.png"
import cv from "../assets/img/cv-curriculum.png"
import maleMan from "../assets/img/male-man.png"
import business from "../assets/img/business-idea.png"
import work from "../assets/img/work-preformance.png"
import Teams from "../components/teams/design-team"
import SSS from "../components/sss/ui-sss"
import checkModal from "../assets/img/check-modal.png";
import Companys from "../components/seo-companys"


class uiUxDesign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Mesajınız başarıyla gönderilmiştir.";
            }
            else {
                document.querySelector('.status').innerHTML = "Bir hata oluştu.";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="UI/UX Tasarım" description="Websitenizde iyi bir kullanıcı deneyimi yaşatmak için etkileşime giden yolu şekillendiriyor, yüksek dönüşüm getirecek arayüzler tasarlıyoruz." />
                <div className="seo-service container">
                    <div className="seo-service__head">
                        <div className="col-md-6 noPadding">
                            <h1 >UI/UX<br />Tasarım</h1>
                            <span className="red-head">HİZMETLER</span>
                            <p style={{ marginTop: 25 }}>Hedef kitlenize websitenizde iyi bir kullanıcı deneyimi yaşatmak için etkileşime giden yolu şekillendiriyor, yüksek dönüşüm getirecek arayüzler tasarlıyoruz.</p>
                            <input type="text" className="pink-input" placeholder="E-posta adresinizi giriniz." value={this.state.value} onChange={this.handleChange} />
                            <button className="red-button" id="uiuxButton" onClick={(e) => this.showModal()}>Ücretsiz UX Audit</button>
                        </div>
                        <div className="col-md-6" style={{ paddingLeft: 15, marginTop: 40 }}><img alt="alt text" src={uiuxDesign} /> </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Formu doldurun.</h2>
                            <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                            <div className="check-modal_container">
                                <form onSubmit={this.handleSubmit} >
                                    <input type="text" name="text_204202" id="site" required  placeholder="Websitenizi girin." />
                                    <input type="text" id="name" required name="text_775908" placeholder="Adınızı girin." />
                                    <input type="email" id="email" required  name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="E-posta adresinizi girin." />
                                    <button  >GÖNDER</button>
                                    <div className="status"></div>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uiUx container" style={{marginTop:50}}>
                    <div className="seo-referances_company py60">
                        {/* <div className="companyz_left">
                            <p><span>+%28</span></p>
                            <p style={{ margin: '10px 0px' }}>Reklam etkileşim oranı Pakmaya çikolata kampanyası</p>
                            <Link>Çalışma detaylarına göz atın.</Link>
                            <img alt="alt text" style={{ height: 60, margin: '20px 0px' }} src={pakmaya} />
                        </div> */}
                        <Companys />
                    </div>

                </div>
                <div className="uiUx-solutions">
                    <h2>UI/UX Tasarım Çözümlerimiz.</h2>
                    <div className="uiUx-solutions-row py60">
                        <div className="uiUx-solutions-row_img col-md-6">
                            <img alt="alt text" id="mobil-scale" src={uiuxSolitions} />
                        </div>
                        <div className="uiUx-solutions-row_content col-md-6">
                            <h3>Kullanıcı Araştırması,<br />
                                Şema Tasarımı ve <br />
                                Prototipler</h3>
                            <p>Kullanıcılarınızı anlamaya çalışıyor, websitenize girdiklerinde ne hissedecekleri ile ilgileniyoruz. </p>
                            <p>Bu doğrultuda kullanıcılarınızı tanıyabilmek için yaptığımız araştırmaların verilerini analiz ediyor, oluşan fikirlerin şema tasarımını yapıyor, prototiplerini oluşturuyoruz.</p></div>
                    </div>
                    <div className="uiUx-solutions-row">
                        <div className="uiUx-solutions-row_img2 col-md-6">
                            <img alt="alt text" src={uiuxSolitions2} />
                        </div>
                        <div className="uiUx-solutions-row_content col-md-6">
                            <h3>Kullanıcı Arayüzü (UI)<br />Tasarımı</h3>
                            <p>UX tasarım süreci sonucunda websiteniz için belirlediğimiz renk, hiyerarşi, görsel, tipografi, kompozisyon gibi tasarım ögelerini kullanıcı arayüzü üzerinde hayata geçiriyor ve ortaya pazarlama hedeflerinize uygun bir websitesi çıkarıyoruz.</p>
                        </div>
                    </div>
                </div>

                <div className="development-studio container py60">
                    <h2>UI/UX tasarım stüdyomuzda hazırlanan <br /> ve ön plana çıkan işlerimizden bazıları. </h2>
                    <div className="tcdd-dev">
                        <img alt="alt text" src={tcddDev}></img>
                        <img alt="alt text" className="lines" src={lines}></img>
                        <h3>TCDD Taşımacılık <br /> Website Geliştirme & UX Tasarım</h3>
                        <p>TCDD yolcularının seyahat planlarını kolayca uygulayabilecekleri, pratik,<br /> işlevsel ve kullanıcı odaklı bir website geliştirdik.</p>
                        <img alt="alt text" className="lavarel" src={lavarel}></img>
                    </div>
                    <div className="popupsmart-dev py60">
                        <div className="row">
                            <div className="col-md-5">
                                <h3>Popupsmart<br />
                                    Website & Mobil <br />
                                    Uygulama Geliştirme</h3>
                                <p>Dönüşüm optimizasyonu toolkit’i Popupsmart için pratik adımlarla popup oluşturmayı mümkün kılan bir website ve uygulama geliştirdik.</p>
                                <p>Popupsmart müşterileri, websitelerine zahmetsiz bir şekilde popup entegre edebiliyor, dönüşümlerini optimize edebiliyorlar.</p>
                                <Link href="https://popupsmart.com/tr" className="content-link">Çalışma detaylarına göz atın.</Link><br />
                                <img alt="Popupsmart" className="vuejs" src={vue} />
                            </div>
                            <div className="col-md-6">
                                <img alt="Popupsmart" src={popupsmartDev} />

                                <img alt="Popupsmart" className="circle" src={circle} />
                            </div>
                        </div>
                    </div>
                    <div className="popupsmart-dev py60">
                        <div className="row">
                            <div className="col-md-5">
                                <h3>Arçelik Perakende Akademi<br />
                                Web Page & Mobil APP <br />
                                UI&UX Tasarım</h3>
                                <p>Bayi çalışanlarının motivasyonunu ve eğitim süreçlerini güçlendirmek için geliştirilen mobil uygulamanın arayüzünü kullanıcı deneyimine uygun şekilde tasarladık. Uygulamada yer alan gamification öğeleri ve kurguları ile eğitim süreçlerini daha etkileşimli ve keyifli hale getirdik.</p>
                                <p>Arçelik Perakende Akademi'nin eğitmen kadrolarının faaliyet gösterdiği bölgeleri ve illeri web sitesi ziyaretçilerine kolay ve etkileşimli şekilde bilgilendirmek amacıyla web sitesinin ilgili sayfasını ele alarak sıfırdan tasarladık.</p>
                                
                            </div>
                            <div className="col-md-6">
                                <img alt="Toyota" src={arcelikk} />

                                <img alt="Toyota" className="circle" src={circle} />
                            </div>
                        </div>
                    </div>
                    <div className="popupsmart-dev py60">
                        <div className="row">
                            <div className="col-md-5">
                                <h3>Toyota<br/>
                                UI&UX Tasarımı</h3>
                                <p>Toyota Türkiye'nin resmi web sitesi için veri ve pazarlama hedeflerine yönelik, tamamen kullanıcı odaklı araştırmalarımızı her sayfa özelinde sürdürüyor, ziyaretçilere daha kolay ve kullanıcı dostu bir yol çizmek adına yenilikleri ay bazlı yayına aldığımız süreçler yürütüyoruz. 
Yenilenen sayfa deneyimleri ve tasarımlarıyla otomotiv sektöründeki rakiplerin bir adım önünde ilerleme hedefiyle veri odaklı yaklaşımlarımızı hayata geçirmeye devam ediyoruz.</p>                                
                            </div>
                            <div className="col-md-6">
                                <img alt="alt text" src={toyota} />

                                <img alt="alt text" className="circle" src={circle} />
                            </div>
                        </div>
                    </div>
                </div>

                <Gallery name="web" />

                <div className="container manage-google manage-uiUx">
                    <h2 style={{ marginTop: 20 }}>UI/UX tasarım için <br /> neden bizi seçmelisiniz?</h2>
                    <p className="manage-google_p">Kullanıcıların sorun yaşamayacağı ve dolayısıyla  aradıklarını bularak <br /> dönüşüm gerçekleştirebilecekleri kullanıcı deneyimi odaklı arayüz tasarımları yapıyoruz.</p>
                    <div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={balance} />
                            <div>
                                <h3>İleri Seviyede Uzmanlarla Çalışma</h3>
                                <p>UI/UX tasarımları stajyer veya az deneyimli kişiler değil, alanında uzman profesyonel bir ekip yönetir.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={achievement} />
                            <div>
                                <h3>Değer Oluşturma</h3>
                                <p>Yatırım geri dönüşünüzü artırmak için etkili çalışmaya odaklanır ve belirlediğimiz KPI’ları sürekli olarak ölçümleriz.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={videoSearch} />
                            <div>
                                <h3>Etkili İletişim Yöntemleri </h3>
                                <p>UI/UX tasarımların performansını gözden geçirmek ve yeni fikirleri tartışmak için sizinle düzenli olarak  görüşmeler gerçekleştiririz.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={cv} />
                            <div>
                                <h3>Size Özel Rapor</h3>
                                <p>Tüm performans göstergelerinizin sonuçlarını ve markanız için en önemli istatistikleri, düzenli olarak rapor halinde sunarız.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={maleMan} />
                            <div>
                                <h3>Marka İmajı Önceliği</h3>
                                <p>Websiteniz ve uygulamanız, markanızla ilgili ilk izlenimin oluştuğu yerlerdir. Bu nedenle tasarımlarımızda kullanıcıların beklentilerinin yanı sıra markanızın imajını da göz önünde bulunduruyoruz.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={business} />
                            <div>
                                <h3>Uygun Fiyatlandırmalar</h3>
                                <p>Etkili ve sonuca ulaşan e-posta pazarlama kampanyaları için yüksek ücretler ödemek zorunda kalmazsınız.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="onboarding container" style={{ marginTop: 100, marginBottom: 30 }}>
                    <div className="col-md-4">
                        <img alt="alt text" src={work} />
                        <h2>İşleyiş <br />sürecimiz.</h2>
                        <p>Markalarımız için <br />
                            UI/UX tasarım sürecimizi <br />
                            nasıl sürdürdüğümüze göz atın.</p>
                    </div>
                    <div className="col-md-8">
                        <div className="hiring-tabs">
                            <input type="checkbox" name="onboarding" id="tab6" />
                            <input type="checkbox" name="onboarding" id="tab7" />
                            <input type="checkbox" name="onboarding" id="tab8" />
                            <input type="checkbox" name="onboarding" id="tab9" />
                            <input type="checkbox" name="onboarding" id="tab10" />
                            <input type="checkbox" name="onboarding" id="tab11" />

                            <div className="tab" id="tab6sec">
                                <label htmlFor="tab6"> </label>
                                <h3> <span>1</span> Keşif görüşmesi yapılır.</h3>
                                <p>- Hedefleriniz, rekabet avantajlarınız, hedef kitleniz, performansınızı etkileyen faktörler gibi markanızı ve hizmet sunduğunuz sektörü olabildiğince iyi tanımak için öncelikle bir görüşme gerçekleştiriyoruz. </p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab7sec">
                                <label htmlFor="tab7"> </label>
                                <h3> <span>2</span>Mevcut website ve uygulamalar incelenir.</h3>
                                <p>- Stratejilerimizi oluştururken yararlanacağımızı veriler doğrultusunda mevcut performansınızı analiz ediyoruz, eksik noktaları tespit ediyoruz.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab8sec">
                                <label htmlFor="tab8"> </label>
                                <h3> <span>3</span> Sonraki adımlara geçmek üzere <br />bulgular konuşulur.</h3>
                                <p>- Kullanıcı araştırmaları sonucunda ortaya çıkan içgörüleri, ayrıntılı iş planını ve yatırım getirisi hesaplamalarını sunmak için yüz yüze veya görüntülü bir konuşma gerçekleştiriyoruz.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab9sec">
                                <label htmlFor="tab9"> </label>
                                <h3> <span>4</span> Başlangıç stratejisi oluşturulur ve aksiyona geçilir.</h3>
                                <p>- Belirlenen strateji doğrultusunda prototipler geliştirilerek kullanıcı odaklı arayüzler tasarlıyor ve hayata geçiriyoruz.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab10sec">
                                <label htmlFor="tab10"> </label>
                                <h3> <span>5</span>Optimizasyonlar ve aylık detaylı raporlamalar sürdürülür.</h3>
                                <p>- Performansı gözden geçirmek ve yeni fikirleri sunmak üzere sizinle haftalık olarak görüşüyoruz. </p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab11sec">
                                <label htmlFor="tab11"> </label>
                                <h3> <span>6</span> Her üç ayda bir gelecek çeyreğe ilişkin<br /> stratejik planlama oturumları gerçekleştirilir. </h3>
                                <p>- Devam eden optimizasyonlar ve stratejik plan masaya yatırılarak sonraki döneme ilişkin ayrıntılı bir analiz değerlendirmesi yapılır, bütçenizin en yüksek değer sunacak şekilde yönetilmesi sağlanır.</p>
                                <div className="plus"></div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="team">
                    <h2 style={{ textAlign: 'center', marginBottom: 120 }}> Size yardımcı olabilecek <br />UX/UI tasarımcılarımız. </h2>
                    <Teams />
                </div>*/}
                <div className="hiring" style={{ marginTop: -20 }}>
                    <h2>Sıkça Sorulan Sorular</h2>
                    <SSS />
                </div>
            </Layout>
        )
    }
}
export default uiUxDesign
