import React from "react"



const SSS = () => (


    <div className="hiring-tabs">
        <input type="checkbox" name="dropdown" id="tab1" />
        <input type="checkbox" name="dropdown" id="tab2" />
        <input type="checkbox" name="dropdown" id="tab3" />
        <input type="checkbox" name="dropdown" id="tab4" />
        <input type="checkbox" name="dropdown" id="tab5" />

        <div className="tab" id="tab1sec">
            <label htmlFor="tab1"> </label>
            <h3>UI/UX tasarımlar için hangi teknolojileri kullanıyorsunuz?</h3>
            <p>Website ve uygulamalarınızda müşteri deneyimi geliştirmek için 
en son sürüm Vue.js, ASP.NET Core, Adobe ürünlerini kullanıyoruz. </p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab2sec">
            <label htmlFor="tab2"> </label>
            <h3>UIUX tasarımları SEO ve reklam çalışmalarını etkiler mi?</h3>
            <p>Evet, iyi bir UI/UX tasarımı tüm dijital faaliyetlerinizi olumlu yönde etkiler. 
Çünkü Google, kullanıcı deneyimi üst seviyede olan websitelere değer 
vermekte ve onları daha ön plana çıkarmaktadır.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab3sec">
            <label htmlFor="tab3"> </label>
            <h3>UI ve UX arasındaki fark nedir?</h3>
            <p>UI, kullanıcı arayüzü anlamına gelmekte olup kullanıcıların erişebildiği 
website ve uygulama arayüzünüzün tasarımı ile ilgilenir. UX ise kullanıcı 
deneyimi demektir ve kullanıcıların beklentilerini anlamakla ilgilidir.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab4sec">
            <label htmlFor="tab4"> </label>
            <h3>UI/UX tasarımı, mevcut website üzerinde yapılabilir mi?</h3>
            <p>Elbette. Mevcut altyapınızı kullanarak kullanıcı beklentilerine uygun 
arayüzler tasarlanabilir.  </p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
    
    </div>
)

export default SSS
